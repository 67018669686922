<template>
  <div class="kt-widget14">
    <div class="kt-widget14__header">
      <h3 class="kt-widget14__title">
        Monthly Expenses
      </h3>
      <span class="kt-widget14__desc">
        Expenses charged by Users this month
      </span>
    </div>
    <div class="kt-widget14__content">
      <div class="kt-widget14__chart">
        <div class="kt-widget14__stat">{{ total }} K</div>
        <Chart1
          ref="chart"
          v-bind:options="chartOptions"
          height="150"
          width="150"
        ></Chart1>
      </div>
      <div class="kt-widget14__legends">
        
        <div class="kt-widget14__legend" 
          v-for="(legend, k) in this.chartData.legends"
          :key="k"
          >
          <span class="kt-widget14__bullet kt-bg-primary"></span>
          <span class="kt-widget14__stats">{{ legend.legend }}</span>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Chart1 from "@/views/partials/widgets/Chart1.vue";

export default {
  name: "widget-14-3",
  components: {
    Chart1
  },
  data() {
    return {
      chartOptions: {},
      total: 0
    };
  },
  
  props: [
    "title", 
    "desc", 
    "chartData"
  ],

  watch: { 
    chartData: function(newVal) {
      this.chartData = newVal
      this.setChartData();
    }
  },

  mounted() {
    this.setChartData();
  },

  methods: {
    setChartData() {
        this.chartOptions = {
        type: "doughnut",
        data: this.chartData,
        options: {
          cutoutPercentage: 75,
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            position: "top"
          },
          title: {
            display: false,
            text: "Technology"
          },
          animation: {
            animateScale: true,
            animateRotate: true
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: "rgba(0,0,0,0.5)",
            titleFontColor: "#ffffff",
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0
          }
        }
      };

      this.total = this.chartData.total

    }
  },

  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>
