<template>
  <div class="kt-widget4">
    <perfect-scrollbar style="max-height: 30vh; position: relative;">
      <template v-for="(item, i) in chartData">
        <div class="kt-widget4__item" :key="`item-${i}`">
          <div class="kt-widget4__pic kt-widget4__pic--pic">
            <i class="kt-menu__link-icon flaticon-coins" style="font-size: 2em;"></i>
          </div>
          <div class="kt-widget4__info">
            <a :href="item.button.link" class="kt-widget4__username">{{ item.username }}</a>
            <p class="kt-widget4__text">{{ item.text }}</p>
          </div>
          <a
            :href="item.button.link"
            class="btn"
            v-bind:class="item.button.style"
            >{{ item.button.text }}</a
          >
        </div>
      </template>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: "widget-4",
  components: {},
  
  props: [
    "chartData"
  ],

  watch: { 
    chartData: function(newVal) {
      this.chartData = newVal
    }
  },

};
</script>
