<template>
  <div class="kt-widget14">
    <div class="kt-widget14__header kt-margin-b-30">
      <h3 class="kt-widget14__title">
        Worked Time Chart
      </h3>
      <span class="kt-widget14__desc">
        Time used by user this month
      </span>
    </div>
    <div class="kt-widget14__chart">
      <Chart1 ref="chart" v-bind:options="chartOptions"></Chart1>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Chart1 from "@/views/partials/widgets/Chart1.vue";

export default {
  name: "WidgetWorkedHours",
  components: {
    Chart1
  },
  data() {
    return {
      chartOptions: {}
    };
  },
  
  props: [
    "title", 
    "desc", 
    "chartData"
  ],

  watch: { 
    chartData: function(newVal) {
      this.chartData = newVal
      this.setChartData();
    }
  },

  mounted() {
    this.setChartData();
  },

  methods: {
    setChartData() {
      this.chartOptions = {
        type: "bar",
        data: this.chartData,
        options: {
          title: {
            display: false
          },
          tooltips: {
            intersect: false,
            mode: "nearest",
            xPadding: 10,
            yPadding: 10,
            caretPadding: 10
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false,
          barRadius: 0,
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: true,
                stacked: true
              }
            ],
            yAxes: [
              {
                display: true,
                stacked: false,
                gridLines: {
                  display: true,
                },
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          }
        }
      };
    }
  },

  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>
