<template>
  <div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-lg-1 order-xl-1">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
            <WidgetWorkedHours v-if="initWidgetWorkedHoursChartDataLoaded"
              :chartData="widgetWorkedHoursChartData"
            ></WidgetWorkedHours>
          </template>
        </KTPortlet>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-lg-2 order-xl-2">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
            <WidgetPendingLou  v-if="initWidgetPendingLouLoaded"
              :chartData="widgetPendingLouData"
            ></WidgetPendingLou>
          </template>
        </KTPortlet>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-lg-3 order-xl-3">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
            <WidgetMonthlyExpenses  v-if="initWidgetMonthlyExpensesLoaded"
              :chartData="widgetMonthlyExpensesData"
            ></WidgetMonthlyExpenses>
          </template>
        </KTPortlet>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-lg-3 order-xl-3">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
            <WidgetRecentNominations  v-if="initWidgetRecentNominationsLoaded"
              :chartData="widgetRecentNominationsData"
            ></WidgetRecentNominations>
          </template>
        </KTPortlet>
      </div>

    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-lg-3 order-xl-3">
        
        <KTPortlet v-bind:title="'Dormant WO'">
          
          <template v-slot:toolbar>
            <ul
              class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-brand"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  v-on:click="openDetailDormantWO"
                  data-tab="0"
                  class="nav-link active"
                  data-toggle="tab"
                  href="#"
                  role="tab"
                >
                  View Detail
                </a>
              </li>
            </ul>
          </template>

          <template v-slot:body>
            
            <WidgetsDormantWO  v-if="initWidgetDormantWOLoaded"
              :chartData="widgetDormantWOData"
            ></WidgetsDormantWO>
            
          </template>
        </KTPortlet>
        
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-lg-3 order-xl-3">
        <KTPortlet
          v-bind:title="'Unpaid'"
          v-bind:class="'kt-portlet--tabs kt-portlet--height-fluid'"
        >
          <template v-slot:toolbar>
            <ul
              class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-brand"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  v-on:click="setActiveTab2"
                  data-tab="0"
                  class="nav-link active"
                  data-toggle="tab"
                  href="#"
                  role="tab"
                >
                  Invoices
                </a>
              </li>
              <li class="nav-item">
                <a
                  v-on:click="setActiveTab2"
                  data-tab="1"
                  class="nav-link"
                  data-toggle="tab"
                  href="#"
                  role="tab"
                >
                  Expenses
                </a>
              </li>
            </ul>
          </template>
          <template v-slot:body>
            <b-tabs class="kt-hide-tabs" v-model="tabIndex2">
              <b-tab active>
                <WidgetInvoices  v-if="initWidgetInvoicesLoaded"
                  :chartData="widgetInvoicesData"
                ></WidgetInvoices>
              </b-tab>
              <b-tab>
                <WidgetExpenses  v-if="initWidgetExpensesLoaded"
                  :chartData="widgetExpensesData"
                ></WidgetExpenses>
              </b-tab>
            </b-tabs>
          </template>
        </KTPortlet>
      </div>
    </div>
    <!--<div class="row">
      <div class="col-lg-6 col-xl-4 order-lg-1 order-xl-1">
        <KTPortlet v-bind:title="'Recent Activities'">
          <template v-slot:body>
            <WidgetRecentActivities v-bind:datasrc="widget_recent_activities"></WidgetRecentActivities>
          </template>
        </KTPortlet>
      </div>
      <div class="col-xl-6 col-lg-6 order-lg-2 order-xl-2">
        <KTPortlet
          v-bind:title="'New Users'"
          v-bind:class="'kt-portlet--tabs kt-portlet--height-fluid'"
        >
          <template v-slot:toolbar>
            <ul
              class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-brand"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  v-on:click="setActiveTab1"
                  data-tab="0"
                  class="nav-link active"
                  data-toggle="tab"
                  href="#"
                  role="tab"
                >
                  Today
                </a>
              </li>
              <li class="nav-item">
                <a
                  v-on:click="setActiveTab1"
                  data-tab="1"
                  class="nav-link"
                  data-toggle="tab"
                  href="#"
                  role="tab"
                >
                  Month
                </a>
              </li>
            </ul>
          </template>
          <template v-slot:body>
            <b-tabs class="kt-hide-tabs" v-model="tabIndex">
              <b-tab active>
                <WidgetUsers v-bind:datasrc="widgetUsers"></WidgetUsers>
              </b-tab>
              <b-tab>
                <WidgetUsers v-bind:datasrc="widgetUsers"></WidgetUsers>
              </b-tab>
            </b-tabs>
          </template>
        </KTPortlet>
      </div>
    </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
//import ContextMenu1 from "@/views/partials/widgets/ContextMenu1.vue";
//import WidgetUsers from "@/views/partials/widgets/Widget_Users.vue";
import WidgetInvoices from "@/views/partials/widgets/Widget_Invoices.vue";
import WidgetExpenses from "@/views/partials/widgets/Widget_Expenses.vue";
import WidgetWorkedHours from "@/views/partials/widgets/Widget_WorkedHours.vue";
import WidgetPendingLou from "@/views/partials/widgets/Widget_PendingLou.vue";
import WidgetRecentNominations from "@/views/partials/widgets/Widget_RecentNominations.vue";
import WidgetMonthlyExpenses from "@/views/partials/widgets/Widget_MonthlyExpenses.vue";
//import Widget17 from "@/views/partials/widgets/Widget17.vue";
//import Widget20_1 from "@/views/partials/widgets/Widget20_Inbound.vue";
//import Widget20_2 from "@/views/partials/widgets/Widget20_Outbound.vue";
//import WidgetRecentActivities from "@/views/partials/widgets/Widget_RecentActivities.vue";
import WidgetsDormantWO from "@/views/partials/widgets/Widget_DormantWO.vue";

/**
 * Sample widgets data source
 */
//import widget_recent_activities from "@/common/mock/widget-recent-activities.json";
//import dormants_wo from "@/common/mock/widget-dormant-wo.json";
//import widgetUsers from "@/common/mock/widget-users.json";
//import widgetInvoices from "@/common/mock/widget-invoices.json";
//import widgetExpenses from "@/common/mock/widget-expenses.json";
import genericService from "./../../services/generic.service";

export default {
  name: "dashboard",
  components: {
    KTPortlet,
    //ContextMenu1,
    //WidgetUsers,
    WidgetExpenses,
    WidgetInvoices,
    WidgetWorkedHours,
    WidgetPendingLou,
    WidgetMonthlyExpenses,
    WidgetRecentNominations,
    //Widget17,
    //Widget20_1,
    //Widget20_2,
    //WidgetRecentActivities,
    WidgetsDormantWO
  },
  data() {
    return {
      //widget_recent_activities: widget_recent_activities,
      //dormants_wo: dormants_wo,
      //widgetUsers: widgetUsers,
      tabIndex: 0,
      tabIndex2: 0,
      
      widgetWorkedHoursChartData: {},
      widgetPendingLouData: {},
      widgetMonthlyExpensesData: {},
      widgetRecentNominationsData: {},
      widgetDormantWOData: {},
      widgetInvoicesData: {},
      widgetExpensesData: {},

      initWidgetWorkedHoursChartDataLoaded: false,
      initWidgetPendingLouLoaded: false,
      initWidgetMonthlyExpensesLoaded: false,
      initWidgetRecentNominationsLoaded: false,
      initWidgetDormantWOLoaded: false,
      initWidgetInvoicesLoaded: false,
      initWidgetExpensesLoaded: false

    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },

  computed: {
    ...mapGetters(["layoutConfig"])
  },

  created() {
    this.initWidgetWorkedHoursChartData();
    this.initWidgetPendingLouData();
    this.initWidgetMonthlyExpensesData();
    this.initWidgetRecentNominationsData();
    this.initWidgetDormantWOData();
    this.initWidgetInvoicesData();
    this.initWidgetExpensesData();  
  },

  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    initWidgetWorkedHoursChartData() {
      var _self = this; 
      genericService.getReport("admin_worked_hours", function(reportData){
        if(reportData != undefined) {
          _self.widgetWorkedHoursChartData = reportData;
          _self.initWidgetWorkedHoursChartDataLoaded = true
        }
      });
    },

    initWidgetPendingLouData() {
      var _self = this; 
      genericService.getReport("admin_pending_lou", function(reportData){
        if(reportData != undefined) {
          _self.widgetPendingLouData = reportData;
          _self.initWidgetPendingLouLoaded = true
        }
      });
    },

    initWidgetMonthlyExpensesData() {
      var _self = this; 
      genericService.getReport("admin_monthly_expenses", function(reportData){
        if(reportData != undefined) {
          _self.widgetMonthlyExpensesData = reportData;
          _self.initWidgetMonthlyExpensesLoaded = true
        }
      });
    },

    initWidgetRecentNominationsData() {
      var _self = this; 
      genericService.getReport("admin_recent_nominations", function(reportData){
        if(reportData != undefined) {
          _self.widgetRecentNominationsData = reportData;
          _self.initWidgetRecentNominationsLoaded = true
        }
      });
    },

    initWidgetDormantWOData() {
      var _self = this; 
      genericService.getReport("admin_dormant_wo", function(reportData){
        if(reportData != undefined) {
          _self.widgetDormantWOData = reportData;
          _self.initWidgetDormantWOLoaded = true
        }
      });
    },

    initWidgetInvoicesData() {
      var _self = this; 
      genericService.getReport("admin_invoices", function(reportData){
        if(reportData != undefined) {
          _self.widgetInvoicesData = reportData;
          _self.initWidgetInvoicesLoaded = true
        }
      });
    },

    initWidgetExpensesData() {
      var _self = this; 
      genericService.getReport("admin_expenses", function(reportData){
        if(reportData != undefined) {
          _self.widgetExpensesData = reportData;
          _self.initWidgetExpensesLoaded = true
        }
      });
    },

    openDetailDormantWO() {
      window.open('#/dashboard/admin/detaildormantwo');
    }

  }
};
</script>
